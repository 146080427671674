import Vue from 'vue'
import {
  Form, Field, Toast, Button,
} from 'vant'
import { mapState, mapActions } from 'vuex'
const { Base64 } = require('js-base64')
import { Uploader } from 'vant'

Vue.use(Uploader)
Vue.use(Button)
Vue.use(Form)
Vue.use(Field)
Vue.use(Toast)

export default {
  components: {},
  data () {
    return {
      upload: true,
      fileListRen: [],
      fileListGuo: [],
      renfile: '',
      guofile: '',
      timer: ""

    }
  },
  created () {
    // console.log(localStorage.getItem("openId"),55555555)
  },

  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer) //关闭
    }
  },
  methods: {
    ...mapActions('home', ['supplementCard', 'uploadImage']),

    back () {

      this.$router.go(-1)

    },

    afterReadRen (file) {
      if (this.fileListRen.length == 0 || this.fileListGuo.length == 0) {
        this.upload = true
      } else {
        this.upload = false
      }
      const data = new FormData()
      data.append("image", file.file)
      this.uploadImage(data).then((res) => {
        if (res.code == 200) {
          this.renfile = res.data

        }
      })
    },
    afterReadGuo (file) {
      if (this.fileListRen.length == 0 || this.fileListGuo.length == 0) {
        this.upload = true
      } else {
        this.upload = false
      }
      // 此时可以自行将文件上传至服务器
      const data = new FormData()
      data.append("image", file.file)
      this.uploadImage(data).then((res) => {
        if (res.code == 200) {
          this.guofile = res.data
        }
      })
    },
    async afterDelete (file) {
      this.isloadImg = true
      return new Promise((resolve, reject) => {
        console.log("删除文件的回调,afterDelete")
        this.isloadImg = false
        this.upload = true
        resolve()
      })
    },
    beforeRead (file) {
      if (file instanceof Array) {
        for (let i = 0; i < file.length; i++) {
          if (file[i].type == 'image/jpeg' || file[i].type == 'image/png') {
            return true
          } else {
            Toast('请上传 png/jpg 格式图片')
            return false
          }
        }
      } else {
        if (file.type == 'image/jpeg' || file.type == 'image/png') {
          return true
        }
        Toast('请上传 png/jpg 格式图片')
        return false
      }
    },
    onSubmit () {
      this.supplementCard({
        front: this.renfile,
        back: this.guofile,
        openid: localStorage.getItem("openId")
      }).then((res) => {
        if (res.code == 200) {
          //shenFenCard 0不显示身份认证操作 1显示
          localStorage.setItem('shenFenCard', 0)
          Toast(res.msg)
          if (this.$route.query.card == 1) {
            this.timer = setInterval(() => {
              this.$router.push({
                name: 'Warranty2', query: { jump: 0 }
              })     //这里是要做到事情
            }, 2000)
          } else {
            this.timer = setInterval(() => {
              this.$router.push({
                name: 'Mine'
              })     //这里是要做到事情
            }, 2000)
          }

        } else {
          Toast(res.msg)
        }
      })
    },




  },
}
